import React from 'react'

function Loading() {
    return (
        <div>
            <div className='y-loader'></div>
        </div>
    )
}

export default Loading